import React from 'react'
import Collection from './collection'
import useOptions from '../hooks/use-options'
import slugify from '../utils/slugify'

export default function Recommendations ({ products }) {
  const { store: { recommendationsTitle } } = useOptions()
  const collection = {
    title: recommendationsTitle,
    handle: slugify(recommendationsTitle),
    products
  }
  return (
    <section className='u-sectionDivider'>
      <Collection
        collection={collection}
      />
    </section>
  )
}
