import React, { useRef, useEffect, useState } from 'react'
import Slider from 'react-slick'
import LazyImg from './lazy-img'

export default function Carousel ({ images }) {
  const nav1 = useRef()
  const nav2 = useRef()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!ready && nav1.current && nav2.current) {
      setReady(true)
    }
  }, [ready, nav1, nav2])

  return (
    <div className='Carousel-gallery'>
      <div className='Carousel-galleryImg'>
        <Slider
          ref={nav1}
          asNavFor={nav2.current}
          swipeToSlide
          autoplay
          // autoplaySpeed={2000}
          arrows={false}
          focusOnSelect={false}
        >
          {images.map(img => (
            <div key={`img_${img.thumb}`} style={{ outline: 'none' }}>
              <div className='Carousel-galleryImgWrapper'>
                <div className='Carousel-galleryImgContainer u-mediaContainer is-ready'>
                  <LazyImg src={img.src} alt={img.alt} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {/*
      */}
      <div style={{ width: '100%' }}>
        <Slider
          ref={nav2}
          asNavFor={nav1.current}
          slidesToShow={Math.min(images.length, 4)}
          arrows={false}
          focusOnSelect
          swipeToSlide={false}
          draggable={false}
        >
          {images.map((img, i) => (
            <div key={`thumb_${img.thumb}`}>
              <div className='Carousel-galleryImgWrapper'>
                <div
                  key={`thumb_${img.thumb}`}
                  className='Carousel-galleryThumbContainer u-mediaContainer'
                >
                  <LazyImg src={img.thumb} alt={img.alt} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
