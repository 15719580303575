import React from 'react'
import Html from './html'
import Carousel from './carousel'
import ProductTitle from './product-title'
import BuyButton from './buy-button'
import useOptions from '../hooks/use-options'
import { Link } from 'gatsby'

export default function ProductFull ({ product, onBuy }) {
  const {
    title,
    slug,
    content,
    featuredImage,
    productImages
  } = product

  const images = productImages.images.map(item => item.image.mediaItemUrl)
  if (!images.length) {
    images.push(featuredImage.mediaItemUrl)
  }

  const { store: { productPageDeliveryText, discoverCubettoLabel } } = useOptions()

  return (
    <section id={slug} className='u-sectionDivider'>
      <div className='Product Product--primary Product--tease'>
        <div className='Product-image'>
          {images.length > 1 ? (
            <Carousel
              images={images.map(item => ({
                src: item,
                thumb: item,
                alt: title
              }))}
            />
          ) : (
            <img
              src={images[0]}
              alt={title}
            />
          )}
        </div>
        <div className='Product-info'>
          <div className='Product-content'>
            <div>
              <ProductTitle title={title} heading />
              {/*
                <ProductPrice variant={variant} available={available} large />
              */}
              <div className='Product-description Page-content'>
                <Html>{content}</Html>
              </div>
            </div>
          </div>
            <div className='Product-buttons'>
              <BuyButton button />
              {/* {!available && (
                <h5 className='Product-outstock'>
                  Out of stock
                </h5>
              )} */}
              <Link className='Button Button--outline Button--tomato' to='/cubetto/'>
                {discoverCubettoLabel}
              </Link>
            </div>
            <div className='Product-description Page-content'>
              <Html>{productPageDeliveryText}</Html>
            </div>
        </div>
      </div>
      {/*
      <Json object={product} />
      */}
    </section>
  )
}
