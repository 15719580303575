import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ProductFull from '../components/product-full'
// import Json from '../components/json'
import Recommendations from '../components/recommendations'
import ShopNotices from '../components/shop-notices'
// import NewsLetter from '../components/newsletter'
import ImagesAndVideos from '../components/images-and-videos'
import sortRandom from 'usfl/array/sort-random'

export default function ProductPage ({ data, location, pageContext }) {
  const { wpgraphql: { page, shop } } = data

  const recommended = shop.productCollections.shopCollection
    .reduce((arr, item) => {
      return [...arr, ...item.products]
    }, [])
    .filter(item => {
      return item.product.slug !== page.slug
    })
    .sort(sortRandom)
    .slice(0, 3)

  return (
    <Layout
      page={page}
      template='product'
      path={location.pathname}
    >
      <article>
        <section className='u-sectionPadded u-headerMargin'>
          <ProductFull
            product={page}
          />
        </section>
        <Recommendations
          products={recommended}
        />
        <section className='u-sectionDivider'>
          <ImagesAndVideos data={shop.imagesAndVideos} />
        </section>
        <section className='u-sectionPadded u-sectionDivider'>
          <ShopNotices />
        </section>
        {/* <NewsLetter /> */}
        {/* <Json object={product} /> */}
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageProductQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...ProductImagesFragment
      }
      ...ShopPageFragment
    }
  }
`
